import React, { useEffect, useState } from "react"
import { Div, Row, Col, Text, StyleReset, Container, ThemeProvider, Anchor, currentDevice } from "atomize"
import { StaticImage } from "gatsby-plugin-image"
import PageTransition from "gatsby-plugin-page-transitions"
import "../settings/index.css"
import "../settings/iubenda.css"
import SEO from "../components/seo"
import Header from "../components/header"

import CoverWave from "../images/assets/w-home1.svg"
import MetricsWaveWhite from "../images/assets/w-collaborate-white.svg"
import MetricsWaveRed from "../images/assets/w-collaborate-red.svg"
import Footer from "../components/footer"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamation } from "@fortawesome/free-solid-svg-icons"
import SubheaderText from "../components/SubheaderText"
import BoldText from "../components/BoldText"
import HeadingText from "../components/HeadingText"
import { Link } from "gatsby"
import Squiseat from "../videos/squiseat-video-founder.mp4"

let config = require("../settings/config")

const theme = config.theme
const url = config.url

const MetricsEntry = props => {
    return (
        <Col size={{ xs: "12", md: "6" }} p={{ t: "2rem" }}>
            <Text textColor="white" textAlign="center" textSize={{ xs: "display2", lg: "display3" }} textWeight="bold">
                {props.number}
            </Text>
            <Text textColor="white" textAlign="center" textSize={{ xs: "subheader", md: "title", lg: "heading" }}>
                {props.text}
            </Text>
        </Col>
    )
}

const IndexPage = () => {
    const [screenWidth, setScreenWidth] = useState(0)
    useEffect(() => {
        currentDevice() === "lg" || currentDevice() === "xl"
            ? setScreenWidth(window.innerWidth - window.innerWidth * 0.2)
            : setScreenWidth(window.innerWidth)
    }, [])

    const ImageEntry = props => {
        return (
            <Col size={{ xs: "12", md: "4", lg: "3" }}>
                <Div m={{ y: "0.5rem" }}>
                    <Anchor href={props.link} target="_blank">
                        {props.children}
                    </Anchor>
                </Div>
            </Col>
        )
    }

    return (
        <PageTransition>
            <ThemeProvider theme={theme}>
                <StyleReset />
                <Header />
                <SEO
                    title="L'app antispreco italiana senza sorprese!"
                    description="Squiseat offre un servizio per fermare gli sprechi alimentari pasto dopo pasto. Scarica la nostra app antispreco, e ordina ora dai nostri partner a Bologna!"
                />

                <Div tag="main" pos="relative" overflow="hidden">
                    <Div
                        tag="section"
                        bg="primary"
                        pos="relative"
                        p={{
                            t: {
                                xs: "1rem",
                                sm: "2rem",
                                lg: "8rem",
                                xl: "10rem",
                            },
                        }}
                    >
                        <Container>
                            <Text tag="h1" d="none">
                                Scopri Squiseat, la prima app antispreco italiana!
                            </Text>
                            <Text tag="h2" d="none">
                                Scarica l'app e aiutaci a ridurre gli sprechi, pasto dopo pasto!
                            </Text>
                            <Text tag="h2" d="none">
                                Eccedenze? ECCELLENZE! Scegli cibo di alta qualità a metà prezzo!
                            </Text>
                            <Row>
                                <Col size={{ xs: 12, lg: 8 }} pos="relative" zIndex="2">
                                    <Text
                                        textSize={{
                                            xs: "display2",
                                            md: "display3",
                                        }}
                                        textWeight="bold"
                                        textColor="white"
                                        p={{ t: "0.5rem" }}
                                    >
                                        Lasagna invenduta sempre piaciuta
                                    </Text>
                                    <SubheaderText m={{ t: { xs: "1rem", lg: "2rem" } }} textColor="white">
                                        Con Squiseat <BoldText textColor="white">scegli</BoldText> tra le{" "}
                                        <BoldText textColor="white">rimanenze</BoldText> dei negozi vicini a te prima
                                        che vengano sprecate (e non solo lasagne).
                                    </SubheaderText>
                                    <SubheaderText
                                        m={{ t: { xs: "1rem", lg: "2rem" } }}
                                        textWeight="bold"
                                        textColor="white"
                                    >
                                        Risparmia, le paghi la metà!
                                    </SubheaderText>
                                    <Div d={{ md: "flex" }} m={{ t: "1rem" }} justify="start">
                                        <Anchor
                                            href="https://squiseat-subdomain.onelink.me/0GIO/WebsiteIOS"
                                            id="download_ios_home"
                                            target="_blank"
                                            title="Scarica per iPhone | Squiseat"
                                        >
                                            <Div
                                                w={{
                                                    xs: "10rem",
                                                    md: "14rem",
                                                }}
                                                m={{ xs: "1rem auto", md: "1rem 0.5rem 0 0" }}
                                            >
                                                <StaticImage
                                                    src="../images/pulsante-download-app-store-ios.png"
                                                    alt="Pulsante per il download su App Store iOS"
                                                    placeholder="blurred"
                                                />
                                            </Div>
                                        </Anchor>

                                        <Anchor
                                            href="https://squiseat-subdomain.onelink.me/0GIO/WebsiteAndroid"
                                            id="download_android_home"
                                            target="_blank"
                                            title="Scarica per smartphone Android | Squiseat"
                                        >
                                            <Div
                                                w={{
                                                    xs: "10rem",
                                                    md: "14rem",
                                                }}
                                                m={{ xs: "1rem auto", md: "1rem 0 0 0.5rem" }}
                                            >
                                                <StaticImage
                                                    src="../images/pulsante-download-play-store-android.png"
                                                    alt="Pulsante per il download su Play Store Android"
                                                    placeholder="blurred"
                                                />
                                            </Div>
                                        </Anchor>
                                    </Div>
                                </Col>
                                <Col>
                                    <Div
                                        w={{ xs: "8rem", md: "15rem", lg: "calc(13rem + 11vw)" }}
                                        right="0"
                                        left={{ xs: "0", lg: "unset" }}
                                        m={{ x: { xs: "auto", lg: "unset" }, t: { xs: "1rem", lg: "-3rem" } }}
                                        pos="absolute"
                                        zIndex="1"
                                        transform={{ xs: "rotate(30deg)", lg: "rotate(10deg)" }}
                                    >
                                        <StaticImage
                                            src="../images/app-squiseat-sezione-locali.png"
                                            alt="Sezione locali dell'app"
                                            placeholder="blurred"
                                            className="logo"
                                        />
                                    </Div>
                                </Col>
                            </Row>
                            <Div h={{ xs: "10rem", md: "18rem", lg: "0" }} />
                        </Container>
                    </Div>

                    <Div tag="section">
                        <Div m={{ t: { xs: "-1.25rem", lg: "-5rem" } }}>
                            <CoverWave className="wave" />
                        </Div>
                        <Container pos="relative" zIndex="1">
                            <Row m={{ y: { xs: "3rem", md: "5rem" } }} align="center">
                                <Col size={{ xs: "12", md: "7" }} style={{ padding: "0" }}>
                                    <HeadingText p={{ y: "0.5rem" }}>
                                        L'<BoldText textColor="primary">app antispreco</BoldText> più{" "}
                                        <BoldText textColor="primary">trasparente</BoldText> del reame.
                                    </HeadingText>
                                    <SubheaderText p={{ t: { xs: "0.5rem", md: "1.5rem" } }}>
                                        "In che senso <BoldText>trasparente</BoldText>?"
                                    </SubheaderText>
                                    <SubheaderText p={{ t: { xs: "1rem", md: "2rem" } }}>
                                        Abbiamo un <BoldText>punto di forza</BoldText>: la{" "}
                                        <BoldText textColor="primary">scelta</BoldText> dei prodotti!
                                    </SubheaderText>
                                    <SubheaderText>
                                        Ah giusto, anche che sono a <BoldText textColor="primary">metà prezzo</BoldText>
                                        .
                                    </SubheaderText>
                                    <Link to={url.how} title="Ecco come ordinare! | Squiseat">
                                        <SubheaderText m={{ t: { xs: "1rem", md: "2rem" } }} textColor="gunmetal">
                                            👉🏻 "<BoldText textDecor="underline">Spiega meglio!</BoldText>"
                                        </SubheaderText>
                                    </Link>
                                </Col>
                                <Col
                                    size={{ xs: "12", md: "5" }}
                                    textSize={{ xs: "heading", md: "display2", lg: "display3" }}
                                    textAlign="right"
                                >
                                    <Div
                                        m={{ t: { xs: "-3rem", md: "0" }, l: { xs: "52vw", md: "5vw", lg: "10vw" } }}
                                        pos="relative"
                                        zIndex="2"
                                    >
                                        <Div
                                            w={{ xs: "9rem", md: "15rem", lg: "20rem" }}
                                            left={{ xs: "0", lg: "unset" }}
                                            m={{ x: { xs: "auto", lg: "unset" } }}
                                            pos="absolute"
                                            transform="rotate(35deg)"
                                        >
                                            <StaticImage
                                                src="../images/app-squiseat-sezione-prodotti.png"
                                                alt="Logo di Squiseat negativo e senza testo"
                                                placeholder="blurred"
                                                className="logo"
                                            />
                                        </Div>
                                        <FontAwesomeIcon icon={faExclamation} size="10x" color="transparent" />
                                    </Div>
                                </Col>
                            </Row>
                        </Container>
                    </Div>

                    <Div tag="section" m={{ y: "2rem" }}>
                        <Container>
                            <Row m={{ y: { xs: "3rem", md: "8rem" } }} align="center">
                                <Col
                                    textSize={{ xs: "heading", md: "display2", lg: "display3" }}
                                    size={{ xs: "12", md: "5" }}
                                    textAlign="right"
                                    order={{ xs: 2, md: 1 }}
                                >
                                    <Div
                                        m={{ t: { xs: "-3rem", lg: "0" }, l: { xs: "52vw", md: "1vw", lg: "-2vw" } }}
                                        pos="relative"
                                        zIndex="2"
                                    >
                                        <Div
                                            w={{ xs: "9rem", md: "15rem", lg: "20rem" }}
                                            left={{ xs: "0", lg: "unset" }}
                                            m={{ x: { xs: "auto", lg: "unset" } }}
                                            pos="absolute"
                                            transform={{ xs: "rotate(35deg)", lg: "rotate(25deg)" }}
                                        >
                                            <StaticImage
                                                src="../images/app-squiseat-locali-con-filtri.png"
                                                alt="Logo di Squiseat negativo e senza testo"
                                                placeholder="blurred"
                                                className="logo"
                                            />
                                        </Div>
                                        <FontAwesomeIcon icon={faExclamation} size="10x" color="transparent" />
                                    </Div>
                                </Col>
                                <Col size={{ xs: "12", md: "7" }} order={{ xs: 1, md: 2 }} style={{ padding: "0" }}>
                                    <HeadingText p={{ y: "0.5rem" }}>
                                        Perché <BoldText textColor="primary">Squiseat</BoldText>?
                                    </HeadingText>
                                    <SubheaderText p={{ t: { xs: "0.5rem", md: "1.5rem" } }}>
                                        "Non esiste già qualcosa di simile?" Sì,{" "}
                                        <BoldText textColor="primary">tipo</BoldText>.
                                    </SubheaderText>
                                    <SubheaderText p={{ t: { xs: "1rem", md: "2rem" } }}>
                                        <BoldText>Ma non potevi scegliere vero?</BoldText>
                                    </SubheaderText>
                                    <SubheaderText>
                                        E come fa uno se è <BoldText>allergico</BoldText> o ha delle{" "}
                                        <BoldText>preferenze alimentari</BoldText> particolari (la voglia di lasagne è
                                        compresa 😅)?
                                    </SubheaderText>
                                    <SubheaderText p={{ t: { xs: "1rem", md: "2rem" } }}>
                                        Facile, <BoldText>metti due filtri</BoldText> e via, vedi i locali con i
                                        prodotti che ti interessano!
                                    </SubheaderText>
                                    <Link to={url.why} title="I nostri obiettivi | Squiseat">
                                        <SubheaderText m={{ t: { xs: "1rem", md: "2rem" } }} textColor="gunmetal">
                                            👉🏻 "<BoldText textDecor="underline">Avete ragione!</BoldText>"
                                        </SubheaderText>
                                    </Link>
                                </Col>
                            </Row>
                        </Container>
                    </Div>

                    <Div tag="section" pos="relative" zIndex="2">
                        <Container>
                            <Row m={{ y: { xs: "3rem", md: "8rem" } }} align="center">
                                <Col size={{ xs: "12", md: "7" }} style={{ padding: "0" }}>
                                    <HeadingText p={{ y: "0.5rem" }}>
                                        <BoldText textColor="primary">Hai un locale</BoldText>? Sai di cosa parliamo.
                                    </HeadingText>
                                    <SubheaderText p={{ t: { xs: "0.5rem", md: "1.5rem" } }}>
                                        Sei a fine giornata e hai ancora il <BoldText>banco mezzo pieno</BoldText> di{" "}
                                        <BoldText>prodotti</BoldText> di oggi, ma soprattutto{" "}
                                        <BoldText>ancora buonissimi</BoldText>!
                                    </SubheaderText>
                                    <SubheaderText p={{ t: { xs: "1rem", md: "2rem" } }}>
                                        Difficili da vendere, ma{" "}
                                        <BoldText textColor="primary">ti possiamo dare una mano</BoldText> per
                                        guadagnarci davvero, ti va?
                                    </SubheaderText>
                                    <Link to={url.business} title="Hai un'attività? Collabora con noi | Squiseat">
                                        <SubheaderText m={{ t: { xs: "1rem", md: "2rem" } }} textColor="gunmetal">
                                            👉🏻 <BoldText textDecor="underline">Scopri come</BoldText>
                                        </SubheaderText>
                                    </Link>
                                </Col>
                                <Col
                                    size={{ xs: "12", md: "5" }}
                                    textSize={{ xs: "heading", md: "display2", lg: "display3" }}
                                    textAlign="right"
                                >
                                    <Div
                                        m={{ t: { xs: "-3rem", md: "0" }, l: { xs: "52vw", md: "5vw", lg: "10vw" } }}
                                        pos="relative"
                                        zIndex="2"
                                    >
                                        <Div
                                            w={{ xs: "9rem", md: "15rem", lg: "20rem" }}
                                            left={{ xs: "0", lg: "unset" }}
                                            m={{ x: { xs: "auto", lg: "unset" } }}
                                            pos="absolute"
                                            transform="rotate(35deg)"
                                        >
                                            <StaticImage
                                                src="../images/app-squiseat-locali-caricamento-disponibilità.png"
                                                alt="Logo di Squiseat negativo e senza testo"
                                                placeholder="blurred"
                                                className="logo"
                                            />
                                        </Div>
                                        <FontAwesomeIcon icon={faExclamation} size="9x" color="transparent" />
                                    </Div>
                                </Col>
                            </Row>
                        </Container>
                    </Div>
                    <Div tag="section" bg="primary" pos="relative" zIndex="1">
                        <MetricsWaveWhite
                            className="wave_reverse"
                            style={{
                                marginTop: "-0.25rem",
                            }}
                        />
                        <Container>
                            <Row m={{ t: { xs: "1.5rem", lg: "2rem" } }}>
                                <MetricsEntry number="+30000" text="Porzioni vendute" />
                                <MetricsEntry number="+15000" text="Ordini effettuati" />
                            </Row>
                            <Div m={{ t: { xs: "1.5rem", lg: "2rem" } }}>
                                <video controls width="100%" height={(screenWidth * 1080) / 2500}>
                                    <source src={Squiseat} type="video/mp4" />
                                </video>
                            </Div>
                            <Row m={{ t: { lg: "2rem" } }}>
                                <MetricsEntry number="+90" text="Attività partner" />
                                <MetricsEntry number="+100.000 €" text="Di materie prime recuperate" />
                            </Row>
                        </Container>
                    </Div>

                    <Div tag="section" bg="white" p={{ t: "2rem" }}>
                        <MetricsWaveRed
                            className="wave_rotation_reverse"
                            style={{
                                transform: "rotate(180deg)",
                                marginTop: "-3rem",
                            }}
                        />
                        <Div m={{ t: { xs: "3rem", md: "6rem" } }}>
                            <Container>
                                <Text
                                    textColor="gunmetal"
                                    textSize={{
                                        xs: "display1",
                                        md: "display2",
                                        lg: "display3",
                                    }}
                                    p={{ t: "0.5rem" }}
                                    textWeight="900"
                                >
                                    Parlano di noi
                                </Text>
                                <Div m={{ y: "3rem" }}>
                                    <Row data-sal="slide-up" data-sal-delay="300" align="center">
                                        <ImageEntry link="https://www.legacoop.bologna.it/la-startup-squiseat-ha-vinto-lhackaton-di-think4food/10/2020/">
                                            <StaticImage
                                                src="../images/legacoop-logo.png"
                                                alt="Logo Legacoop"
                                                placeholder="blurred"
                                                layout="fixed"
                                                width={250}
                                                style={{ margin: "auto" }}
                                            />
                                        </ImageEntry>
                                        <ImageEntry link="https://futurefood.network/meet-the-winning-team-of-hack4food-squiseat/">
                                            <StaticImage
                                                src="../images/futurefood-logo.png"
                                                alt="Logo Future Food Network"
                                                placeholder="blurred"
                                                layout="fixed"
                                                width={200}
                                                style={{ margin: "auto" }}
                                            />
                                        </ImageEntry>
                                        <ImageEntry link="https://impact.startupitalia.eu/2021/03/10/squiseat-spreco-cibo-intervista/">
                                            <StaticImage
                                                src="../images/startupitalia-logo.png"
                                                alt="Logo Startup Italia"
                                                placeholder="blurred"
                                                layout="fixed"
                                                width={200}
                                                style={{ margin: "auto" }}
                                            />
                                        </ImageEntry>
                                        <ImageEntry link="https://www.gamberorosso.it/notizie/squiseat-la-startup-di-bologna-che-propone-il-cibo-invenduto-a-meta-prezzo/">
                                            <StaticImage
                                                src="../images/gambero-rosso-logo.png"
                                                alt="Logo Gambero Rosso"
                                                placeholder="blurred"
                                                layout="fixed"
                                                width={200}
                                                style={{ margin: "auto" }}
                                            />
                                        </ImageEntry>
                                        <ImageEntry link="https://www.rtl.it/">
                                            <StaticImage
                                                src="../images/rtl-logo.png"
                                                alt="Logo RTL 102.5"
                                                placeholder="blurred"
                                                layout="fixed"
                                                width={100}
                                                style={{ margin: "auto" }}
                                            />
                                        </ImageEntry>
                                        <ImageEntry link="https://www.tomshw.it/smartphone/5-nuove-app-settimana-19-aprile-2021/">
                                            <StaticImage
                                                src="../images/tomshardware-logo.png"
                                                alt="Logo Tom's Hardware"
                                                placeholder="blurred"
                                                layout="fixed"
                                                width={200}
                                                style={{ margin: "auto" }}
                                            />
                                        </ImageEntry>
                                        <ImageEntry>
                                            <StaticImage
                                                src="../images/carlino-logo.png"
                                                alt="Logo Il Resto del Carlino"
                                                placeholder="blurred"
                                                layout="fixed"
                                                width={250}
                                                style={{ margin: "auto" }}
                                            />
                                        </ImageEntry>
                                        <ImageEntry link="https://firebasestorage.googleapis.com/v0/b/app-clienti-deploy.appspot.com/o/Loghi%2FImmagine%202022-01-02%20171515.jpg?alt=media&token=8d6b5618-37f5-48fd-9ad5-8a3602f5d15c">
                                            <StaticImage
                                                src="../images/repubblica-logo.png"
                                                alt="Logo La Repubblica"
                                                placeholder="blurred"
                                                layout="fixed"
                                                width={200}
                                                style={{ margin: "auto" }}
                                            />
                                        </ImageEntry>
                                        <ImageEntry link="https://www.youtube.com/watch?v=XexHsgwt-EU">
                                            <StaticImage
                                                src="../images/tg1-logo.png"
                                                alt="Logo TG1"
                                                placeholder="blurred"
                                                layout="fixed"
                                                width={100}
                                                style={{ margin: "auto" }}
                                            />
                                        </ImageEntry>
                                        <ImageEntry link="https://www.youtube.com/watch?v=XexHsgwt-EU">
                                            <StaticImage
                                                src="../images/rai1-logo.png"
                                                alt="Logo Rai 1"
                                                placeholder="blurred"
                                                layout="fixed"
                                                width={150}
                                                style={{ margin: "auto" }}
                                            />
                                        </ImageEntry>
                                    </Row>
                                </Div>
                                <Text
                                    textColor="gunmetal"
                                    textSize={{
                                        xs: "display1",
                                        md: "display2",
                                        lg: "display3",
                                    }}
                                    m={{ t: "5rem" }}
                                    p={{ t: "0.5rem" }}
                                    textWeight="900"
                                >
                                    Ci hanno premiato
                                </Text>
                                <Row m={{ y: "3rem" }} data-sal="slide-up" data-sal-delay="300" align="center">
                                    <ImageEntry link="https://socialinnovationteams.org/">
                                        <StaticImage
                                            src="../images/sit-logo.png"
                                            alt="Logo SIT"
                                            placeholder="blurred"
                                            layout="fixed"
                                            width={200}
                                            style={{ margin: "auto" }}
                                        />
                                    </ImageEntry>
                                    <ImageEntry link="https://www.almacube.com/">
                                        <StaticImage
                                            src="../images/almacube-logo.png"
                                            alt="Logo Almacube"
                                            placeholder="blurred"
                                            layout="fixed"
                                            width={200}
                                            style={{ margin: "auto" }}
                                        />
                                    </ImageEntry>
                                    <ImageEntry link="https://startupitalia.eu/131039-20200528-hubble-le-startup-del-batch-oggi-diretta-fb-linkedin">
                                        <StaticImage
                                            src="../images/nanabianca-logo.png"
                                            alt="Logo Nana Bianca"
                                            placeholder="blurred"
                                            layout="fixed"
                                            width={200}
                                            style={{ margin: "auto" }}
                                        />
                                    </ImageEntry>
                                    <ImageEntry link="https://www.econerre.it/innovazione/start-up/upidea-startup-program-le-vincitrici-edizione-2019-20/">
                                        <StaticImage
                                            src="../images/upidea-logo.png"
                                            alt="Logo UpIdea"
                                            placeholder="blurred"
                                            layout="fixed"
                                            width={200}
                                            style={{ margin: "auto" }}
                                        />
                                    </ImageEntry>
                                    <ImageEntry link="https://www.the-hive.it/">
                                        <StaticImage
                                            src="../images/thehive-logo.png"
                                            alt="Logo The Hive"
                                            placeholder="blurred"
                                            layout="fixed"
                                            width={150}
                                            style={{ margin: "auto" }}
                                        />
                                    </ImageEntry>
                                    <ImageEntry link="https://www.fondazionesocialventuregda.it/2021/07/20/myllennium-award-2021-fondazione-social-venture-gda-premia-limpatto-di-squiseat/">
                                        <StaticImage
                                            src="../images/myllennium-logo.png"
                                            alt="Logo MyLLENNIUM AWARD 2021"
                                            placeholder="blurred"
                                            layout="fixed"
                                            width={125}
                                            style={{ margin: "auto" }}
                                        />
                                    </ImageEntry>
                                    <ImageEntry link="https://youtu.be/witZx9BA0pw?t=3797">
                                        <StaticImage
                                            src="../images/sprecozero-logo.png"
                                            alt="Logo Spreco Zero 2021"
                                            placeholder="blurred"
                                            layout="fixed"
                                            width={150}
                                            style={{ margin: "auto" }}
                                        />
                                    </ImageEntry>
                                </Row>
                            </Container>
                        </Div>
                    </Div>
                </Div>

                <Footer />
            </ThemeProvider>
        </PageTransition>
    )
}

export default IndexPage
